
import { defineComponent } from "vue";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "overview",
  components: {
    CodeHighlighter2
  },
  setup() {
    const click = event => {
      const target = event.currentTarget;

      // Activate indicator
      target.setAttribute("data-kt-indicator", "on");

      // Disable indicator after 3 seconds
      setTimeout(function() {
        target.removeAttribute("data-kt-indicator");
      }, 3000);
    };

    return {
      click
    };
  }
});
