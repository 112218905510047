
import { defineComponent, computed, ref, onMounted } from "vue";
import Prism from "prismjs";
import { useCopyClipboard } from "@/core/helpers/documentation";

export default defineComponent({
  name: "CodeHighlighter",
  props: {
    fieldHeight: Number
  },
  components: {},
  setup(props) {
    const height = ref(props.fieldHeight);

    const getHeightInPixesls = computed(() => {
      return height.value + "px";
    });

    const { init } = useCopyClipboard();

    onMounted(() => {
      Prism.highlightAll();
      init();
    });

    const id = () => {
      return Math.random()
        .toString(36)
        .substr(2, 9);
    };

    const htmlId = id();
    const jsId = id();

    return {
      getHeightInPixesls,
      htmlId,
      jsId
    };
  }
});
